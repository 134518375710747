.h3 {
  &-form-control {
    border: 1px solid #3e3e3e;
  }
  &-form-control.error {
    border: 2px solid #d9534f;
  }
  &-select {
  }
  &-textarea {
    margin-top: 15px;
  }
  &-form-inline {
    padding: 20px 0;
    label {
      flex: 1;
    }
    select {
      flex: 1;
    }
    .form-control {
      width: 100%;
    }
  }
  &-button-submit {
    width: 100%;
  }
}
.analyser {
  &-form-inline {
    display: inline-block;
    float: right;
  }
}

.form-control {
  &.error {
    border: 2px solid #d9534f;

    &:focus {
      box-shadow: none;
    }
  }
}

#form-password-reset-from-key {
  max-width: 510px;
  height: 100%;
  width: 100%;
  padding: 15px;
  margin: auto;

  .form-group {
    margin-bottom: 1.8em;
  }

  .errorlist {
    list-style: none;
    padding-left: 0;
    color: #d9534f;
  }

  #id_password1-error,
  #id_password2-error {
    text-align: left;
    color: #d9534f;
    padding-top: 5px;
    font-size: 14px;

    &:before {
      content: none;
    }
  }

  .btn-change {
    background-color: #60cdf6;
    border: 1px solid #60cdf6;
    padding: 0.95em 4.5em;
    font-size: 16px;
    color: #3e3e3e;
    cursor: pointer;
  }
}

.form_sign_in {
  max-width: 525px;
  height: 100%;
  width: 100%;
  padding: 15px;
  margin: auto;

  .form-group {
    margin-bottom: 1.8em;
  }

  input::-webkit-input-placeholder {
    color: #b4b4b4 !important;
    font-size: 18px;
  }

  input:-ms-input-placeholder {
    color: #b4b4b4 !important;
    font-size: 18px;
  }

  input::-ms-input-placeholder {
    color: #b4b4b4 !important;
    font-size: 18px;
  }

  input::placeholder {
    color: #b4b4b4 !important;
    font-size: 18px;
  }

  label:before {
    content: '';
    background: #fff;
    border: solid 1px #3e3e3e;
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    padding: 2px;
    margin-right: 10px;
    text-align: center;
  }

  #id_filtered-by-defectids,
  #id_remember,
  input.custom-checkbox {
    opacity: 0;
    position: absolute;
  }

  .checkbox-input-custom {
    opacity: 0;
    position: absolute;
  }

  #id_filtered-by-defectids:checked + label:before,
  input.custom-checkbox:checked + label:before,
  #id_remember:checked + label:before,
  input.custom-checkbox:checked + label:before {
    content: ' ';
    background-image: url(../../static/images/iconcheck.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .checkbox-input-custom:checked + label:before {
    content: ' ';
    background-image: url(../../static/images/iconcheck.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .checkbox {
    text-align: left;

    label {
      color: #3e3e3e;
      font-size: 14px;
    }
  }

  .btn_sign_in {
    background-color: #60cdf6;
    border: 1px solid #60cdf6;
    padding: 0.95em 4.5em;
    font-size: 16px;
    color: #3e3e3e;
    position: relative;
    min-height: 56px;
  }
  .btn_cancel {
    border: 1px solid #e9ecef;
    padding: 0.95em 4.5em;
    font-size: 16px;
    color: #3e3e3e;
  }
  .forgot_password {
    padding: 1em;

    a {
      color: #3e3e3e;
      &:hover {
        color: #60cdf6;
        text-decoration: none;
      }
    }
  }
  .margin-right-15 {
    margin-right: 15px;
  }
  //  STYLING FOR CUSTOM VALIDATE FORM
  #id_login-error,
  #id_email-error,
  #id_password-error {
    text-align: left;
    color: #d9534f;
    padding-top: 5px;
    font-size: 14px;

    &:before {
      content: none;
    }
  }

  .signin-notification {
    p {
      color: #d9534f;
      padding-bottom: 15px;
    }
  }
}

.form_upload_mission {
  max-width: 770px;
  height: 100%;
  width: 100%;
  padding: 15px;
  margin: auto;

  .btn_reset {
    &:hover {
      color: #60cdf6;
    }
  }

  #id_name-error,
  #building-list-error,
  #id_image-error {
    color: #d9534f;
    padding-top: 5px;
    font-size: 14px;
  }

  .filter-list-mission {
    .select2-container {
      .select2-selection--single {
        border-radius: 3px;
        height: 54px;
        border: 1px solid #3e3e3e;

        .select2-selection__rendered {
          line-height: 54px;
          text-transform: uppercase;
          padding-left: 16px;
          letter-spacing: 0.8px;
          font-size: 11px;
        }

        .select2-selection__arrow {
          height: 54px;
        }
      }
    }
  }
}

.form_billing {
  max-width: 800px;
  height: 100%;
  width: 100%;
  padding: 15px;
  margin: auto;

  .form-group {
    margin-bottom: 1.8em;
    width: 45%;
    margin-right: 2%;
  }
  .form-group-full {
    margin-bottom: 1.8em;
    width: 100%;
  }
  .form-group-button {
    width: 92%;
    margin-bottom: 1.8em;
  }

  input::-webkit-input-placeholder {
    color: #b4b4b4 !important;
    font-size: 18px;
  }

  input:-ms-input-placeholder {
    color: #b4b4b4 !important;
    font-size: 18px;
  }

  input::-ms-input-placeholder {
    color: #b4b4b4 !important;
    font-size: 18px;
  }

  input::placeholder {
    color: #b4b4b4 !important;
    font-size: 18px;
  }

  label:before {
    content: '';
    background: #fff;
    border: solid 1px #3e3e3e;
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    padding: 2px;
    margin-right: 10px;
    text-align: center;
  }

  #id_remember {
    opacity: 0;
    position: absolute;
  }

  .checkbox-input-custom {
    opacity: 0;
    position: absolute;
  }

  #id_remember:checked + label:before {
    content: ' ';
    background-image: url(../../static/images/iconcheck.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .checkbox-input-custom:checked + label:before {
    content: ' ';
    background-image: url(../../static/images/iconcheck.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .checkbox {
    text-align: left;

    label {
      color: #3e3e3e;
      font-size: 14px;
    }
  }

  .btn_sign_in {
    background-color: #60cdf6;
    border: 1px solid #60cdf6;
    padding: 0.95em 4.5em;
    font-size: 16px;
    color: #3e3e3e;
    position: relative;
    min-height: 56px;
  }
  .btn_cancel {
    border: 1px solid #e9ecef;
    padding: 0.95em 4.5em;
    font-size: 16px;
    color: #3e3e3e;
  }
  .forgot_password {
    padding: 1em;

    a {
      color: #3e3e3e;
    }
  }
  .margin-right-15 {
    margin-right: 15px;
  }
  //  STYLING FOR CUSTOM VALIDATE FORM
  #id_login-error,
  #id_email-error,
  #id_password-error {
    text-align: left;
    color: #d9534f;
    padding-top: 5px;
    font-size: 14px;

    &:before {
      content: none;
    }
  }

  .signin-notification {
    p {
      color: #d9534f;
      padding-bottom: 15px;
    }
  }
}
