.loading-wrapper {
  position: relative;
  .loading-content {
    &.active {
      opacity: .5;
    }
  }
  .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}