.frame-map {
  height: 613px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
}
.cd-image-container {
  //position: relative;
  margin-left: auto;
  margin-right: auto;
  padding: 0;

  // Size of container with all responsive

  > div {
    height: 100%;
    position: relative;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
  }
}

.custom-overview-image {
  position: fixed !important;
  height: 100% !important;
  z-index: 100;

  .container-wrapper {
    max-width: 1440px;
    overflow: hidden !important;
  }

  .image-crop-popup {
    top: 45% !important;
    height: 520px !important;
    padding: 0 15px !important;

    p {
      padding: 15px;
    }
  }
}

.href-disabled {
  pointer-events: none;
  cursor: default;
  color: #a2a2a2;
}
