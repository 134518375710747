.link {
  cursor: pointer;
  color: var(--primary);
  text-decoration: none;
  background-color: transparent;
  &:hover {
    color: #0056b3;
    text-decoration: underline;
  }
}
