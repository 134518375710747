.kd-tooltip {
  position: relative;
  display: inline-block;
}

.kd-tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  z-index: 1;
  position: absolute;
  left: -24px;
  top: 34px;
}

.kd-tooltip:hover .tooltiptext {
  visibility: visible;
}

.tool-tip {
  i {
    margin-right: 10px;
  }
  .tool-tip__info {
    color: #6c757d;
    font-size: 11px;
  }
}

div.google-visualization-tooltip {
  z-index: 99 !important;
}

.tooltip-container {
  position: relative;
  display: inline-block;

  $tooltip_width: 150px;

  &:hover .tooltip-content {
    visibility: visible;
    opacity: 0.95;
  }

  .tooltip-content {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 10px 15px;
    border-radius: 6px;

    position: absolute;
    z-index: 99;

    width: $tooltip_width;

    @media only screen and (min-width: 992px) {
      bottom: 140%;
      left: 50%;
      margin-left: calc(#{-$tooltip_width} / 2);

      &:after {
        content: ' ';
        position: absolute;
        top: 100%; /* At the bottom of the tooltip */
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: black transparent transparent transparent;
      }
    }

    @media only screen and (max-width: 991px) {
      top: -37px;
      left: 150%;

      &:after {
        content: ' ';
        position: absolute;
        top: 50%;
        right: 100%; /* To the left of the tooltip */
        margin-top: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent black transparent transparent;
      }
    }

    opacity: 0;
    transition: opacity 0.5s;

    div {
      font-size: 10px;

      img {
        filter: invert(1);
        width: 30px;
        margin-bottom: 8px;
      }
    }
  }
}
