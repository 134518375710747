// Custom
@import './custom.scss';

@import './components/portlet';
@import './components/widget';
@import './components/table';
@import './components/badge';
@import './components/button';
@import './components/progress';
@import './components/loading';
@import './components/label';
@import './components/processBar';
@import './components/warp';
@import './components/input';
@import './components/modal';
@import './components/list';
@import './components/tab';
@import './components//toolBar';
@import './components/tooltip';
@import './components/list';
@import './components/chart';
@import './components/sketchfab';
@import './components/ol';
//page
@import './page/dashboard';
@import './page/building';
@import './page/administrator';
@import './page/inspection';
@import './page/pricing';
@import './page/register';
//elements
@import './elements/form';
@import './elements/image';
@import './elements/header';
@import './elements/menu';
@import './elements/select';
@import './elements/footer';
@import './elements/compare3layers';
@import './elements/link';

// abstract
@import './abstracts/mixins';

p {
  font-size: 14px;
  margin: 0;
  color: #3e3e3e;
}

#root {
  position: relative;
  min-height: 100vh;
}
.hidden {
  display: none !important;
}
body {
  background: #f9f9fc;
  min-height: 100vh;
  padding-right: 0 !important; // To disable padding-right of Bootstrap Modal
}

.pt-30-mb {
  @media screen and (max-width: 1200px) {
    padding-top: 30px;
  }
}
.width_200 {
  width: 200px;
}
.margin_bottom_10 {
  margin-bottom: 10px;
}
.margin_bottom_30 {
  margin-bottom: 30px;
}
.text-align-left {
  text-align: left !important;
}
.padding-top-0 {
  padding-top: 0;
}
.display_inline {
  display: inline-block !important;
}
.app {
  height: calc(100% - 60px);
  border: 2px solid white;
}
.cursor-pointer {
  cursor: pointer;
}

/* Setup Font */
@font-face {
  font-family: 'Exo2.0-Regular';
  src: url(./fonts/Exo2.0-Regular.eot);
  src: local('☺'), url(./fonts/Exo2.0-Regular.woff) format('woff'), url(./fonts/Exo2.0-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Exo2.0-Light';
  src: url(./fonts/Exo2.0-Light.eot);
  src: local('☺'), url(./fonts/Exo2.0-Light.woff) format('woff'), url(./fonts/Exo2.0-Light.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'OCRAStd';
  src: url(./fonts/OCRAStd.eot);
  src: local('☺'), url(./fonts/OCRAStd.woff) format('woff'), url(./fonts/OCRAStd.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Play-Regular';
  src: url(./fonts/Play-Regular.eot);
  src: local('☺'), url(./fonts/Play-Regular.woff) format('woff'), url(./fonts/Play-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
