.data-table {
  border-collapse: collapse;
  overflow: hidden;
  //   display: none;
  width: 100%;
  th,
  td {
    padding: 16px 10px;
    min-width: 120px;
  }
  &__row {
    border-bottom: 1px solid #f0f3ff;
    &:last-child {
      // border-bottom: none;
    }
  }
  &__toggle-detail {
    display: inline-block;
    text-align: right;
    vertical-align: middle;
    color: #5867dd !important;
  }
  &-summary {
    tr {
      td {
        padding: 25px 10px;
        vertical-align: middle;
        font-size: 12px;
      }
    }
    &_active {
      border-top: 15px solid white;
      background: #f5f5f5;
    }
    &_first {
      font-size: 11px;
      text-align: center;
      color: #000000;
      letter-spacing: 0.8px;
      padding: 0 0 34px 0;
      line-height: 12px;
      font-weight: normal;
    }
  }
  &__detail:first-child td {
    border: none;
  }
  &__detail {
    color: #3e3e3e;
    background: #f5f5f5;
    border-top: 15px solid #f9f9fc;
    &:first-child {
      border-top: none;
    }
    &.no-bg {
      background: transparent;
    }
    td {
      vertical-align: middle;
      letter-spacing: 0.1px;
      padding: 26px;
      font-size: 13px;
      & > * {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      select {
        border-color: rgb(204, 204, 204);
      }
      button {
        border-radius: 0;
        padding: 13px 0;
        line-height: 14px;
      }
    }
    .title-table {
      font-weight: 600;
      width: 100px;
    }
  }
}

.table-scrollable-wrapper {
  overflow-y: auto;
  max-height: 700px;
  table {
    margin-bottom: 0px;
  }
  thead {
    th:first-child {
      background: white;
      position: sticky;
      top: 0;
      left: 0;
      font-weight: 400;
      font-size: 12px;
      z-index: 11;
    }
    th {
      background: white;
      position: sticky;
      top: 0;
      font-weight: 400;
      font-size: 12px;
      z-index: 10;
    }
  }
  tbody {
    & tr > td:first-child {
      position: sticky;
      left: 0;
      z-index: 1;
      background: #e3e3e3;
    }
  }
}

.bold {
  font-weight: bold;
}
.table-report {
  tr {
    td,
    th {
      vertical-align: middle;
      i {
        cursor: pointer;
      }
      word-wrap: break-word;
      max-width: 140px;
      font-size: 13px;
    }
  }
}
@media (max-width: 800px) {
  /* Selectors and styles affecting screens UP TO 767px (Tablet) */

  // .header_p > .row {
  //   margin: 0;
  // }

  #building-grid-view .col-item {
    width: 33.333%;
  }

  /*Dashbord*/
  .bg-building-summary {
    padding: 10px 40px;
    padding-right: 0;
  }
  .bg-building-summary .row .building-summary {
    padding-right: 10px !important;
  }
  .bg-building-summary .row .building-summary > .btn-group {
    display: block;
  }
  .elevation-white {
    padding: 30px 30px;
  }
  .elevation-white .face_defects .defects_map {
    width: 100%;
    padding: 0;
  }
  .elevation-dark {
    padding: 61px 40px 0;
  }
  .elevation-white .face_defects .defects_details.mission .defect_image > div:first-child {
    padding-right: 0;
  }
  .elevation-white .face_defects .defects_details.mission .defect_image > div:last-child {
    margin-top: 10px;
  }
  .container-wrapper .upload_elevation {
    padding: 20px;
  }
  .container-wrapper .dropbox_ct #can-upload-elevation-here-id .to-upload {
    width: 50%;
    flex: 0 0 30%;
  }
}

/*-- sreen media 1024 --*/

@media (max-width: 1024px) {
  .bg-app-main {
    margin-top: 0 !important;
  }
  .bg-building-summary .row .building-summary > .btn-group {
    display: block;
  }
  .bg-building-summary .row .building-summary > .btn-group .app-filter {
    width: 100%;
    max-width: unset;
    margin-bottom: 10px;
  }
  .bg-building-summary .row .building-summary > .btn-group .app-title {
    width: 100%;
    max-width: unset;
  }
  .bg-building-summary .row .building-summary {
    padding-right: 20px !important;
  }
  .elevation-white .row .app-filter {
    margin-left: 0;
    margin-top: 10px;
  }

  .elevation-white .row {
    display: block;
  }
}
