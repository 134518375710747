.administrator-page {
  border-radius: 0px;
  // padding: 40px;
  padding: 15px;
  margin-top: 0px;
  margin: auto;
  .modal-content {
    max-height: 90vh;
  }
  .group-filter {
    width: 100%;
    .select-filter {
      margin: 0px;
      width: 100%;
      margin-bottom: 5px;

      @media (min-width: 1366px) {
        width: 300px;
      }
    }
    .btn-disabled-assign {
      float: right;
    }
    #search-building {
      margin: 0px;
    }
    #search-icon {
      height: 100%;
      right: 0px;
    }
    .basic-single {
      display: inline-block;
      // width: 350px;
      width: 100%;
      .select {
        &__control {
        }
        &__value-container {
          padding: 10px 20px;
        }
        &__placeholder {
        }
        &__indicators {
          // display: none;
        }
        &__indicator {
          &-separator {
            display: none;
          }
        }
        &__menu {
          z-index: 5;
        }
        &__dropdown-indicator {
          // display: none;
        }
      }
    }
  }
  .btn-assign,
  .btn-disabled-assign {
    padding: 12px 20px;
  }
  .table-list-user {
    tr {
      td {
        vertical-align: middle;
        i {
          cursor: pointer;
        }
      }
    }
  }
  .react-tabs {
    &__tab-panel {
      border: 1px solid #aaa;
      border-top: none;
      min-height: 200px;
    }
    &__tab-list {
      margin-bottom: 0px;
      padding-left: 20px;
    }
  }
}
.popup-assign {
  &-body {
    input {
      margin-bottom: 20px;
      margin-top: 5px;
      padding: 0px;
      margin-left: 10px;
      height: 39px;
      font-size: 15px;
      padding: 0 10px;
      min-width: 150px;
      border: 1px solid #ccc;
    }
  }
  &-header {
    align-items: center;
    justify-content: center;
    button {
      position: absolute;
      top: 15px;
      right: 20px;
    }
  }
  table {
    tr {
      td {
        vertical-align: middle;
        position: relative;
        // i {
        //   cursor: pointer;
        // }
      }
    }
  }
  .custom-footer-modal {
    position: absolute;
    right: 15px;
    bottom: 15px;
  }
}
.active-pagination {
  z-index: 0;
}
/* The container */
.group-check {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.group-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 10px;
  left: 0;
  bottom: 0px;
  height: 25px;
  width: 25px;
  right: 0px;
  margin: 0 auto;
  margin-top: auto;
  margin-bottom: auto;
  border: 1px solid #c2c2c2;
}

/* On mouse-over, add a grey background color */
.group-check:hover input ~ .checkmark {
  // background-color: #fff;
}

/* When the checkbox is checked, add a blue background */
.group-check input:checked ~ .checkmark {
  background-color: #fff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.group-check input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.group-check .checkmark:after {
  left: 7px;
  top: 2px;
  width: 8px;
  height: 15px;
  border: solid #28a745;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:before {
  content: '';
  position: absolute;
  display: none;
}

/* Style the checkmark/indicator */
.group-check input:not(:checked) ~ .checkmark::before {
  display: block;
}

/* Style the checkmark/indicator */
.group-check .checkmark:before {
  content: '\f00d';
  font-family: FontAwesome;
  font-weight: 900;
  font-style: normal;
  font-size: 15px;
  text-align: center;
  left: 6px;
  top: 1px;
  color: #dc3545 !important;
  // left: 7px;
  // top: 2px;
  // width: 8px;
  // height: 15px;
  // border: solid #28a745;
  // border-width: 0 3px 3px 0;
  // -webkit-transform: rotate(45deg);
  // transform: rotate(45deg);
}

.new-group {
  .btn-assign {
    padding: 0px;
  }
  label {
    line-height: 35px;
    margin-bottom: 0px;
  }
  a {
    line-height: 35px;
    margin-bottom: 0px;
  }
}

.fa-disabled {
  color: #a2a2a2;
}
.inspection {
  .group-filter {
    width: 100%;
    .basic-single {
      width: 100%;
      .select {
        &__dropdown-indicator {
          display: block;
        }
        &__control {
          min-height: 52px;
        }
      }
    }
  }
}

.table-group-management-td {
  display: flex;
  align-items: center;
  justify-content: center;
}

$layout-breakpoint-small: 600px;

@media (max-height: $layout-breakpoint-small) {
  .administrator-page {
    .modal.show .modal-dialog {
      margin-top: 1.75rem !important;
    }
  }
}
