.box {
  width: 50px;
  height: 30px;
  /* margin-top: -25px;
  margin-left: -21px; */
  background: radial-gradient(farthest-side at bottom, #00afff, #0000);
  -webkit-mask: conic-gradient(from -45deg at bottom, #0000, #000 1deg 90deg, #0000 91deg);
  /* -webkit-mask: conic-gradient(from -45deg at bottom,
                    #0000,
                    #000 1deg 43deg,
                    #0000 34deg); */
  /* transition: transform 1s linear; */
  transform-origin: center bottom;
  transform-style: preserve-3D;
}

/* .box {
  width: 10px;
  height: 10px;
  background: red;
  border-top: #000 3px solid;
  transition: transform 2s linear;
  transform-origin: 29% 70%;
  transform-style: preserve-3D;
  -webkit-transform: rotate(-180deg);
  transform: rotate(180deg);
} */
